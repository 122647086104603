/* Importing Bootstrap SCSS file. */
@import 'node_modules/bootstrap/dist/css/bootstrap.min.css';
@import './assets/css/constants';
@import './assets/css/fonts';
@import './assets/css/common';
@import './assets/css/buttons';

.emoji-mart {
  z-index: 9;
}
.text {
  .emoji {
    font-size: 24px;
    vertical-align: middle;
    line-height: 140%;
  }
}
