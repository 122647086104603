@import '../../assets/css/constants';
button, .button {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease-in-out,
  background 0.3s ease-in-out,
  border 0.3s ease-in-out,
  opacity 0.3s ease-in-out;
}
.btn-chat-primary {
  background: $Blue;
  border: 1px solid $Blue;
  border-radius: 3px;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 32px;
  line-height: 120%;
  color: $White;
  text-transform: uppercase;
  &:hover{
   opacity: 0.7;
  }
  &:disabled {
    border: none;
    background: $Gray-70;
  }
  &:focus-visible {
    outline: 2px solid $Gray;
    border: none;
  }
}
.btn-chat-secondary {
  background: $White;
  border: 2px solid $Blue;
  border-radius: 3px;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 32px;
  line-height: 120%;
  color: $Blue;
  text-transform: uppercase;
  &:hover{
    opacity: 0.7;
  }
  &:disabled {
    border: 2px solid $Gray-70;
    color: $Gray-70;
  }
  &:focus-visible{
    border: 2px solid $Gray;
  }
}
