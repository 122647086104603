// Primary
$Dark_gray: #222222;
$Gray: #333333;
$Gray-70: rgba(51, 51, 51, 0.70);
$Gray-20: rgba(51, 51, 51, 0.20);
$Gray-5: rgba(51, 51, 51, 0.05);
$Light_gray: #fafafa;
$White: #ffffff;
$White-70: rgba(255, 255, 255, 0.70);
$Blue: #39a9e0;
$Blue-20: rgba(57, 169, 224, 0.20);
$Blue-30: rgba(57, 169, 224, 0.3);

$Green: #5cb85c;
$Red: #E64A19;

// Secondary
$Secondary_orange: #F3A500;
$Secondary_green: #7BC22F;
$Secondary_red: #920b0b;
