html, body {
  height: 100%;
  touch-action: pan-x pan-y;
}
* {
  font-family: Segoe UI, sans-serif;
}
body {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: $Gray;
}
a {
  text-decoration: none;
  &:focus-visible {
    outline: none;
  }
}
.input-chat{
  border: 1px solid $Gray-20;
  border-radius: 3px;
  width: 100%;
  padding: 9px 16px;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: $Gray;
  box-sizing: border-box;
  outline: 0;
  background: none;
  &::placeholder{
    color: $Gray-70;
    font-size: 16px;
  }
  &.input-border-bottom{
    border: none;
    border-bottom: 1px solid $Gray-70;
    border-radius: 0;
    padding-left: 0;
  }
}
textarea{
  outline: none;
}
.ng-submitted input.ng-invalid{
  &.input-border-bottom{
    border-bottom: 1px solid red;
  }
}

.ng-submitted chat-select.ng-invalid{
  .select-selected{
    border: 1px solid red;
  }
}
.ng-submitted textarea.ng-invalid{
  &.textarea-border-bottom{
    border-bottom: 1px solid red;
  }
  &.textarea-border-full{
    border: 1px solid red;
  }
}

.container-fluid {
  padding: 0 10%;
}

.logo {
  cursor: pointer;
  outline: none;
}

.outline-none {
  outline: none;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.modal-content {
  background: #FFFFFF;
  box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.07);
  border-radius: 25px;
  border: 0;
}

.chats {
  main {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.attachments {
  .modal-dialog {
    max-width: 494px;
  }
}
.chatModal {
  .modal-content {
    background: #FFFFFF;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 48px;
    @media (max-width: 767px) {
      padding: 36px 24px;
    }
  }
  .around-button {
    border-radius: 3px;
    background: #ffffff;
    position: absolute;
    top: -15px;
    right: -15px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.chatModalSlider{
  .modal-content {
    background: none;
    box-shadow: none;
    border-radius: 3px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
app-chat {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.images-slider {
  max-width: 100%;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  .modal-content {
    background: none;
    box-shadow: none;
    .carousel-item {
      height: 70vh;
      text-align: center;
    }
  }
}

//search through chat styles
.search-chat-messages{
  background: $Blue;
  color: $White;
  border-radius: 3px;
  padding: 0 4px;
}
.results-none{
  .search-chat-messages{
    background: initial;
    color: initial;
    border-radius: initial;
    padding: initial;
  }
}

//spinners
.spinner-color{
  color: $Blue;
}
.spinner-small{
  width: 16px;
  height: 16px;
}
.spinner-medium{
  width: 26px;
  height: 26px;
}

//scrollbar
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  background: $Gray-5;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: $Blue;
}
body.image-slider{
  .modal-backdrop {
    --bs-backdrop-opacity: 0.75;
  }
}
