@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/chat/Segoe UI.ttf') format('truetype');
}

@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/chat/Segoe UI Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Segoe UI';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/chat/Segoe UI Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Segoe UI';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/chat/Segoe UI Bold Italic.ttf') format('truetype');
}
